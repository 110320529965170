
  @media print {
    body {
      overflow: visible !important;
      position: static !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      page-break-before: always;
    }
  }
  .page-break {
    page-break-before: always;
  }
  .print {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: var(--white);
    padding: 0px 0px;
}
  /* @media print {
    @page {
      size: A4;
      margin: 1cm;
    }
  } */
  