/*
    CONTENTS
    -- margin
    -- margin X axis auto
    -- margin Y axis auto
    -- margin-top
    -- margin-left
    -- margin-right
    -- margin-bottom
    -- margin-*: auto
 */

.m {
  // margin
  &-auto {
    margin: auto;
  }
  @each $key, $value in $spacer-map {
    &-#{$key} {
      margin: $value;
    }
  }

  // margin X axis
  @each $key, $value in $spacer-map {
    &x-#{$key} {
      margin-left: $value;
      margin-right: $value;
    }
  }

  // margin Y axis
  @each $key, $value in $spacer-map {
    &y-#{$key} {
      margin-top: $value;
      margin-bottom: $value;
    }
  }

  // margin-top
  @each $key, $value in $spacer-map {
    &t-#{$key} {
      margin-top: $value;
    }
  }

  // margin-left
  @each $key, $value in $spacer-map {
    &l-#{$key} {
      margin-left: $value;
    }
  }

  // margin-right
  @each $key, $value in $spacer-map {
    &r-#{$key} {
      margin-right: $value;
    }
  }

  // margin-bottom
  @each $key, $value in $spacer-map {
    &b-#{$key} {
      margin-bottom: $value;
    }
  }

  // margin-*: auto
  @each $key, $side in (t: top, l: left, r: right, b: bottom) {
    &#{$key}-auto {
      margin-#{$side}: auto;
    }
  }

  // margin X axis auto
  &x-auto {
    margin: 0 auto;
  }

  // margin Y axis auto
  &y-auto {
    margin: auto 0;
  }
}