:root {
    --primary: #262B40;
    --secondary: #0061FF;

    --white: #fff;
    --black: #121212;
    --green: #19A872;
    --grey: #9E9E9E;
    --greyLight: #d5d5d5;
    --greyBlue: #8F98AB;
    --red: #f47979;
    --blueLight: #F6F8FA;

    --greyLight-rgb: 213, 213, 213;
    --black-rgb: 0, 0, 0;

    // Sizes
    --header-height: 75px;
}
