.button {
    position: relative;
    padding: 5px 30px 5px 5px;
    cursor: pointer;

    svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
