@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/margin";
@import "/src/assets/styles/includes/mixins";
@import "/src/assets/styles/includes/fonts";
@import "variables/global";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 15px;
}

body,
html {
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--primary);
  font-weight: 400;
  line-height: 1.3;
  background-color: var(--white);
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.15s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button,
input,
textarea {
  background-color: unset;
  border: none;
  font-family: inherit;
  outline: none;
  font-size: inherit;
  color: inherit;
}

button {
  cursor: pointer;
  padding: 0;
  user-select: none;
}

.container {
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.app {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.app-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #bdbdbd;
  }
}

.main {
  flex-grow: 1;
  display: flex;
}


.mapboxgl-popup {
  max-width: unset !important;
  color: #262B40;
  pointer-events: none;
}

.mapboxgl-popup-content {
  font-family: "Roboto", sans-serif;
  padding: 0;
  background-color: transparent;
 
}

.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip{
  align-self: center;
  border-bottom: none;
  border-top-color: black;
  margin-bottom: 10px;
}

.marker-wrapper {
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 8px 12px 8px 8px;
  white-space: nowrap;
  min-width: 240px;
  border: 2px dashed #000000;
}

.marker-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.marker-bottom {
  display: flex;
  font-size: 15px;
}


.marker-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
}

.marker-header-item-value {
  font-size: 15px;
  font-weight: 700;
  color: #262B40;
}

.marker-header-item-name {
  font-size: 10px;
  color: #AAB0BC;
}

.marker-header-item {
  width: 50%;
}

.marker-item-box {
  display: flex;
  align-items: center;
}

.marker-item-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #24D5AA;
  margin-left: 12px;
}

.marker-item-value {
  font-size: 15px;
  font-weight: 700;
  color: #262B40;
}

.marker-footer-btn {
  margin-left: auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #F6F7FA;
  background-image: url("../../../public/assets/mapArrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px 10px;
}

.map-marker {
  width: 25px;
  height: 25px;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  background-image: url('../../../public/icons/marker.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.map-cluster {
  width: 50px;
  height: 50px;
  background-color: #151D26;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  z-index: 2;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  transform: translate(-50%, -50%);

  span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #151D26;
    border-radius: 50%;
    z-index: -1;

    &:nth-child(1) {
      width: 60px;
      height: 60px;
    }

    &:nth-child(2) {
      width: 70px;
      height: 70px;
    }
  }
}